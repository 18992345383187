import * as React from "react";
import styled from "@emotion/styled";
import { colors } from "../styles/variables";

const StyledLayoutMain = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  z-index: 0;
  background: ${colors.background};
`;

interface LayoutMainProps {
  className?: string;
}

const LayoutMain: React.FC<LayoutMainProps> = ({ children, className }) => (
  <StyledLayoutMain className={className}>{children}</StyledLayoutMain>
);

export default LayoutMain;
